import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeApp.vue";


import Features from "../views/FeaturesApp.vue";
import Pricing from "../views/PricingApp.vue";
import About from "../views/AboutApp.vue";
import Security from "../views/SecurityApp.vue";
import ContactUs from "../views/ContactUs.vue";
import Compliance from "../views/ComplianceApp.vue";
import DisplayPrivacy from "../views/DisplayPrivacy.vue";
import SFCDisplayPrivacy from "../views/SFC-DisplayPrivacy.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import AccountDeletion from "../views/AccountDeletion.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeApp",
    component: Home
  },
  {
    path: "/features",
    name: "FeaturesApp",
    component: Features
  },
  {
    path: "/pricing",
    name: "PricingApp",
    component: Pricing
  },
  {
    path: "/about",
    name: "AboutApp",
    component: About
  },
  {
    path: "/security",
    name: "SecurityApp",
    component: Security
  },
  {
    path: "/contactUs",
    name: "ContactUsApp",
    component: ContactUs
  },
  {
    path: "/compliance",
    name: "ComplianceApp",
    component: Compliance
  },
  {
    path: "/displayprivacy",
    name: "DisplayPrivacyApp",
    component: DisplayPrivacy
  },
  {
    path: "/sfcdisplayprivacy",
    name: " SFCDisplayPrivacyApp",
    component:  SFCDisplayPrivacy
  },
  {
    path: "/termsofuse",
    name: "TermsOfUseApp",
    component: TermsOfUse
  },
  {
    path: "/account-or-data-deletion",
    name: "DisplayAccountDeletion",
    component: AccountDeletion
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

export default router;
