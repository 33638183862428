<template>
  <b-container :style="style" class="background">
    <b-modal id="confirmationModal" title="Thank You">
      We will contact you within 24 business hours.
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>

    <h3 class="title">Find out more</h3>

    <b-form @submit.prevent="contact" class="form">
      <b-form-group
        id="nameGroup"
        label="Name"
        class="clear"
        label-for="nameInput"
      >
        <b-form-input v-model="form.name" id="nameInput"></b-form-input>
      </b-form-group>

      <b-form-group
        id="firstNameGroup"
        label="First Name"
        label-for="firstNameInput"
      >
        <b-form-input
          v-model="form.first_name"
          id="firstNameInput"
          trim
          required
          pattern="[A-Za-z]{1,32}"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="lastNameGroup"
        label="Last Name"
        label-for="lastNameInput"
      >
        <b-form-input
          v-model="form.last_name"
          id="lastNameInput"
          trim
          required
          pattern="[A-Za-z]{1,32}"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="phoneGroup" label="Phone" label-for="phoneInput">
        <b-form-input
          v-model="form.phone_number"
          id="phoneInput"
          trim
          required
          type="tel"
          :formatter="formatter"
          pattern="(\+?\d{1,2}?\s?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="companyGroup"
        label="Company Name"
        label-for="companyInput"
      >
        <b-form-input
          v-model="form.firm_name"
          id="companyInput"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="email" label="Email" label-for="email">
        <b-form-input
          v-model="form.email"
          id="email"
          trim
          required
          type="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Contact Options" v-if="showRadios">
        <b-form-radio-group
          id="radio-group-1"
          v-model="requestTag"
          :options="options"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Firm Size" label-for="firmSize">
        <div id="firmSize">
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm1"
            variant="outline-primary"
            @click="selectSize('firmsize_1')"
            >1</b-button
          >
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm2"
            variant="outline-primary"
            @click="selectSize('firmsize_2')"
            >2-4</b-button
          >
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm5"
            variant="outline-primary"
            @click="selectSize('firmsize_5')"
            >5-10</b-button
          >
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm11"
            variant="outline-primary"
            @click="selectSize('firmsize_11')"
            >11-20</b-button
          >
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm21"
            variant="outline-primary"
            @click="selectSize('firmsize_21')"
            >21-50</b-button
          >
          <b-button
            class="mr-3 sizeButton"
            pill
            :pressed.sync="firm50"
            variant="outline-primary"
            @click="selectSize('firmsize_50')"
            >50+</b-button
          >
        </div>
      </b-form-group>

      <b-button type="submit" class="pb-1" variant="custom">
        Submit
        <b-spinner small v-if="sending" />
        </b-button>
    </b-form>
  </b-container>
</template>

<script>
export default {
  name: "ContactForm",
  props: {
    showRadios: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: "request_demo",
    },
    backgroundColor: String,
    textColor: String,
    buttonBackgroundColor: String,
    buttonTextColor: String,
  },
  data() {
    return {
      sending: false,
      form: {
        name: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        firm_name: "",
        email: "",
      },
      options: [
        { text: "Schedule a Demo", value: "request_demo" },
        { text: "Intro Video", value: "request_video" },
        { text: "Webinar", value: "request_webinar" },
        { text: "Phone Call", value: "request_call" },
        { text: "Purchase Now", value: "request_purchase" },
      ],
      requestTag: "request_demo",
      sizeTag: "firmsize_1",
      firm1: true,
      firm2: false,
      firm5: false,
      firm11: false,
      firm21: false,
      firm50: false,
    };
  },
  computed: {
    style() {
      var styleString = {};

      if (this.backgroundColor) {
        styleString["--background-color"] = this.backgroundColor;
      } else {
        styleString["--background-color"] = "white";
      }

      if (this.textColor) {
        styleString["--color"] = this.textColor;
      } else {
        styleString["--color"] = "black";
      }

      if (this.buttonBackgroundColor) {
        styleString["--button-background-color"] = this.buttonBackgroundColor;
      } else {
        styleString["--button-background-color"] = "#003E69";
      }

      if (this.buttonTextColor) {
        styleString["--button-text-color"] = this.buttonTextColor;
      } else {
        styleString["--button-text-color"] = "white";
      }

      return styleString;
    },
  },
  created() {
    this.form.tag = this.tag;
  },
  methods: {
    async contact() {
      this.sending = true;
      this.form['tag'] = [this.requestTag, this.sizeTag];

      try {
        let res = await this.$http.post(
         "https://salesapi.plainwealth.io/process",
       // "http://localhost:3001/process",
          this.form,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );

        if (res.status == 200) {
          this.$bvModal.show("confirmationModal");
          this.form.first_name = "";
          this.form.last_name = "";
          this.form.firm_name = "";
          this.form.last_name = "";
          this.form.phone_number = "";
          this.form.email = "";
        }
      } catch (error) {
        console.log(error);
      }

      this.sending = false;
    },
    ok() {
      this.$bvModal.hide("confirmationModal");
      this.$router.push({ name: "Home" });
   
    },
    selectSize(tag) {
      this.sizeTag = tag
      console.log(tag);
      switch (tag) {
        case "firmsize_1":
          this.firm2 = false;
          this.firm5 = false;
          this.firm11 = false;
          this.firm21 = false;
          this.firm50 = false;
          break;

        case "firmsize_2":
          this.firm1 = false;
          this.firm5 = false;
          this.firm11 = false;
          this.firm21 = false;
          this.firm50 = false;
          break;

        case "firmsize_5":
          this.firm1 = false;
          this.firm2 = false;
          this.firm11 = false;
          this.firm21 = false;
          this.firm50 = false;
          break;

        case "firmsize_11":
          this.firm1 = false;
          this.firm2 = false;
          this.firm5 = false;
          this.firm21 = false;
          this.firm50 = false;
          break;

        case "firmsize_21":
          this.firm1 = false;
          this.firm2 = false;
          this.firm5 = false;
          this.firm11 = false;
          this.firm50 = false;
          break;

        case "firmsize_50":
          this.firm1 = false;
          this.firm2 = false;
          this.firm5 = false;
          this.firm11 = false;
          this.firm21 = false;
          break;
        default:
          break;
      }
    },
    formatter(val) {
      if (val[0] === '(' && val.length === 1) {
        return '';
      }
      var full = val.replace(/\D/g, "");
      var length = full.length;
      var areaCode = full.slice(0,3);
      var firstThree = full.slice(3,6);
      var lastFour = full.slice(6);

      if (length <= 3) {
        return '(' + areaCode;
      } else if (length > 3 && length <= 6) {
        return '(' + areaCode + ')' + ' ' + firstThree;
      } else if (length === 7) {
        return '(' + areaCode + ')' + ' ' + firstThree + '-' + lastFour;
      } else {
        return '(' + areaCode + ')' + ' ' + firstThree + '-' + lastFour.slice(0,4);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";

.background {
  background-color: var(--background-color);
}

h3 {
  color: var(--color) !important;
  font-family: "Poppins", sans-serif;
}

.topOfBut {
  padding-top: 4px;
}

.form {
  ::v-deep label {
    color: var(--color) !important;
    font-family: "Poppins", sans-serif;
  }
}

.btn-custom {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

.clear {
  opacity: 0;
  height: 10px;
}

.sizeButton {
  width: 4.5rem;
}

.active {
  color: white;
}
</style>
