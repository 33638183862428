<template>
  <div id="app" class="background">
    <NavBar/>
     <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <b-row align-h="center">
      <b-col cols="auto" md="auto">
      
        <a id="ccpa" href="#/displayprivacy">CCPA Privacy Notice/Privacy Policy</a>
      </b-col>
      <p>  </p>
    </b-row>
    <b-row align-h="center">
      <b-col cols="auto" md="auto">
        Copyright&copy; 2022
        <a href="https://www.plainsolutions.co">Plain Solutions, LLC</a>
      </b-col>
      <p>  </p>
    </b-row>
     <p> </p>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style lang="scss">
@import "./scss/theme.scss"; 

  .background {
    background-color: white;
    width: 100%;
    height: 100%;
  }
  #ccpa{
    color: blue;

  }
</style>