<template>
  <div>
    <b-container
      :class="getContainerHeight()"
      class="gradient"
      fluid
      style="background: radial-gradient(circle at 0% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%), radial-gradient(circle at 100% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%)"
      v-if=!mobile
    >
      <b-row align-v="center" :class="getContainerHeight()" >
        <b-col class="text-center" >
           <b-img
                src="@/assets/text logo.png"
                contain
                fluid
                style="maxWidth: 80%"
              />
          <h1>A fully compliant experience</h1>   
        </b-col>
          <b-col class="text-center" cols="md-6" >
           <b-img
                src="@/assets/compliance_gear.png"
                contain
                fluid
                style="maxWidth: 80%"
              />
         
        </b-col>
      </b-row>
    </b-container>
    
    <b-container class="mb-5">
      
      <h1 class="text-center" v-if=mobile>Compliance</h1>
        
        <b-img
          src="@/assets/compliance_gear.png"
          fluid
          style="maxWidth: 100%"
          v-if=mobile
         />
      
       
      <b-row class="topPad" v-for="feature in features" :key="feature.name">
        <b-col cols="2" class="text-right" v-if="!isMobile()">
          <ReusableAnimation animation="fade">
            <font-awesome-icon
              :icon="feature.icon"
              class="h-75 w-25 text-right icon"
            />
          </ReusableAnimation>
        </b-col>

        <b-col cols="12" md="9">
          <ReusableAnimation animation="fade">
            <div>
              <h3 :ref="feature.name">{{ feature.name }}</h3>
              {{ feature.details }}
            </div>
          </ReusableAnimation>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ReusableAnimation from "../components/ReusableAnimation.vue";

export default {
  components: {
    ReusableAnimation,
  },
  data() {
    return {
      mobile : false,
      features: [

        {
          name: "Privacy",
          icon: "user-lock",
          details: `We understand the importance of privacy protection to your clients.  Plain Wealth never stores client financial or insurance account information in its databases.  Rather, we pass such information, encrypted from end-to-end, from its source to your client ensuring what it is private, remains private, and only viewed by the client.`},
         {
          name: "App protection",
          icon: "fingerprint",
          details: `We utilize biometrics, two-factor authentication, end-to-end encryption, and other security practices to ensure that Plain Wealth provides a secure client experience.`},
        {
          name: "Communication Rules",
          icon: "envelope-open-text",
          details: `Our systems were built on guidance provided by industry experts in the field of the rules and reporting requirements of FINRA Rule 2210, SEC’s Rule 206(4)-1, and others.  We provide you simple tools within the Plain Wealth System to ensure that you, your employees, agents and representatives remain fully compliant as your clients use the Plain Wealth app.`},
        {
          name: "Secure Storage",
          icon: "key",
          details: `Data is processed and stored in a manner congruent with FINRA’s recommended best practices.`
          },
          {
          name: "Adaptable",
          icon: "exchange-alt",
          details: `We recognize that each firm may implement their compliance procedures differently.  We have ways to adapting to your firm’s practices in this regard.  Let’s talk and show you how.`
          },
      ],
      
    };
  },

     created() {
       if (window.innerWidth < 576){
         this.mobile = true;
       }
     },
  methods: {
    isMobile() {
      return window.innerWidth < 576;
    },
    getContainerHeight() {
      return this.mobile ? "fullHeightMobile" : "fullHeight";
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../scss/theme.scss";
.topPad {
  padding-top: 4rem;
}
</style>