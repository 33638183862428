<template>
  <b-container>
  <b-row class="pb-3">
    
          <b-col cols="12" md="5" class="text-center mb-4">
            <p></p>
            <p></p>
            <h3>Information</h3>

            <b>Email</b>
            <p>support@plainwealth.io</p>

            <b>Address</b>
            <p>
              Plain Solutions LLC<br />
              Dept: Plain Wealth<br />
              121 N. Mead<br />
              Suite 109<br />
              Wichita, KS 67202
            </p>
          </b-col>

          <b-col>
            <p></p>
            <p></p>
            <ContactForm />
          </b-col>
        </b-row>
  </b-container>

</template>

<script>
import ContactForm from "../components/ContactForm.vue";

export default {
  components: {
    ContactForm
  }
}
</script>