<template>
  <div>
    <b-container
      class="text-center gradient"
      :class="shouldBeTall()"
      style="background: radial-gradient(circle at 0% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%), radial-gradient(circle at 100% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%);"
      fluid
    >
      <div :style="getContainerStyle" class="container">
        <h1 class="label-text" v-if="!mobile">Features</h1>
        <h1 class="label-text-mobile" v-if="mobile">Features</h1>
        <b-img
          src="../assets/phone_feature.png"
          contain
          fluid="lg"
          v-if="!mobile"
        />
        <b-img
          src="../assets/mobile_features.png"
          contain
          fluid
          v-if="mobile"
        />
      </div>
    </b-container>
    <div v-if=!mobile>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
   
    </div>
     
    <h3 class="text-center my-5">Benefits</h3>
    <b-container fluid="xl" v-if="!mobile">
      <b-row class="my-3">
        <b-col class="ml-5">
          <ReusableAnimation animation="slideLeft">
            <HelpfulCard
              cardTitle="Get Connected"
              cardBody="But it’s more than that to the advisor—the app is a platform for the advisor to build greater connectedness with clients and strengthen the advisor/client relationship. "
            />
          </ReusableAnimation>
        </b-col>

        <b-col class="mr-5">
          <ReusableAnimation animation="slideRight">
            <HelpfulCard
              cardTitle="Easy Access"
              cardBody="In recent years expectations of financial advisor clients has changed considerably.  Many of today’s clients want quick, easy and frequent access to information that is of interest…like account balances."
            />
          </ReusableAnimation>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col class="ml-5">
          <ReusableAnimation animation="slideLeft">
            <HelpfulCard
              cardTitle="Quick Access"
              cardBody="Some want to check their balances daily, others weekly, and others just occasionally.  Clients want to be able to get the information as quickly and easily as they can get their banking or credit card or mortgage balance information."
            />
          </ReusableAnimation>
        </b-col>

        <b-col class="mr-5">
          <ReusableAnimation animation="slideRight">
            <HelpfulCard
              cardTitle="Meet Virtually"
              cardBody="Many clients are in constant use of their mobile phone throughout every day—it’s the tool they use to get information.  The Plain Wealth app allows the advisor to meet their client where they live—on their mobile devices."
            />
          </ReusableAnimation>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col class="ml-5">
          <ReusableAnimation animation="slideLeft">
            <HelpfulCard
              cardTitle="Better Serve Clients"
              cardBody="The most basic value of the Plain Wealth app for the advisor is that it helps the advisor better serve their clients without burdening the advisor—the app is a productivity and customer satisfaction tool."
            />
          </ReusableAnimation>
        </b-col>

        <b-col class="mr-5">
          <ReusableAnimation animation="slideRight">
            <HelpfulCard
              cardTitle="Generate Referrals"
              cardBody="The Plain Wealth app provides the perfect opportunity to delight clients and generate referrals.  With a click of a button, a client can refer you potential business. Grow your book of business."
            />
          </ReusableAnimation>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="mobile">
      <b-row class="my-3">
        <ReusableAnimation animation="slideLeft">
          <HelpfulCard
            cardTitle="Get Connected"
            cardBody="But it’s more than that to the advisor—the app is a platform for the advisor to build greater connectedness with clients and strengthen the advisor/client relationship. "
          />
        </ReusableAnimation>
      </b-row>

      <b-row class="my-3">
        <ReusableAnimation animation="slideLeft">
          <HelpfulCard
            cardTitle="Easy Access"
            cardBody="In recent years expectations of financial advisor clients has changed considerably.  Many of today’s clients want quick, easy and frequent access to information that is of interest…like account balances."
          />
        </ReusableAnimation>
      </b-row>

      <b-row class="my-3">
        <ReusableAnimation animation="slideRight">
          <HelpfulCard
            cardTitle="Quick Access"
            cardBody="Some want to check their balances daily, others weekly, and others just occasionally.  Regardless, most don’t want to hassle with contacting their advisor or advisor firm office to get such basic information.  They want to be able to get the information as quickly and easily as they can get their banking or credit card or mortgage balance information."
          />
        </ReusableAnimation>
      </b-row>

      <b-row class="my-3">
        <ReusableAnimation animation="slideLeft">
          <HelpfulCard
            cardTitle="Work Virtually"
            cardBody="For most clients, especially those who are younger, they are in constant use of their mobile phone throughout every day—it’s the tool they use to get information.  The Plain Wealth app allows the advisor to meet their client where they live—on their mobile devices."
          />
        </ReusableAnimation>
      </b-row>

      <b-row class="my-3">
        <ReusableAnimation animation="slideRight">
          <HelpfulCard
            cardTitle="Better Serve Clients"
            cardBody="The most basic value of the Plain Wealth app for the advisor is that it helps the advisor better serve their clients without burdening the advisor—the app is a productivity and customer satisfaction tool."
          />
        </ReusableAnimation>
      </b-row>

      <b-row class="my-3">
        <ReusableAnimation animation="slideRight">
          <HelpfulCard
            cardTitle="Support Clients"
            cardBody="The Plain Wealth helps the advisor stay connected to and support their clients."
          />
        </ReusableAnimation>
      </b-row>
    </b-container>

    <b-container>
      <b-row class="topPad" v-for="feature in features" :key="feature.name">
        <b-col cols="2" class="text-right" v-if="!mobile">
          <ReusableAnimation animation="fade">
            <font-awesome-icon
              :icon="feature.icon"
              class="h-75 w-25 text-right icon"
            />
          </ReusableAnimation>
        </b-col>

        <b-col cols="12" md="9">
          <ReusableAnimation animation="fade">
            <div>
              <h3 :ref="feature.name">{{ feature.name }}</h3>
              {{ feature.details }}
            </div>
          </ReusableAnimation>
        </b-col>
      </b-row>
    </b-container>

    <div class="bottomPad" />
  </div>
</template>

<script>
import HelpfulCard from "../components/HelpfulCard.vue";
import ReusableAnimation from "../components/ReusableAnimation.vue";

export default {
  data() {
    return {
      mobile: false,
      features: [
        {
          name: "Branded",
          icon: "clone",
          details: `Setup the app to reflect your firm's brand, logo, and colors.`,
        },
        {
          name: "Referrals",
          icon: "share-square",
          details: `Referrals are made easy in Plain Wealth.  Within seconds, a client can refer their friend, family member, or professional contact.`,
        },
        {
          name: "View Accounts",
          icon: "wallet",
          details: `Client's can view a summary of their compiled and charted financial accounts and
          other relevant, non-transactional account information
          View the balance and other relevant, non-transactional information of
          individual financial accounts.
          View the “managed” account information that the advisor oversees.
          Depending on how the advisor firm Redtail Technology CRM is setup,
          the client may also be able to view account information from
          “external” account sources.`,
        },
        {
          name: "Contact Advisor",
          icon: "address-card",
          details: `Client's can place a direct call or send an email to their advisor from within the app.
           They can also read a bio and see a photo of their advisor and 
          request a meeting with or service from their advisor—the advisor would receive the request as an email.`,
        },
        {
          name: "Send Information To The Client",
          icon: "paper-plane",
          details: `With the blog/newsletter plugin, the client can access your latest articles and news right within the app.`,
        },
      ],
    };
  },
     created() {
       if (window.innerWidth < 576){
         this.mobile = true;
       }
     },
  components: {
    HelpfulCard,
    ReusableAnimation,
  },
  methods: {
    scrollToElement(name) {
      const el = this.$refs[name][0];
      console.log(el);

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    isMobile() {
      return window.innerWidth < 576;
    },
    getContainerStyle() {
      if (!this.isMobile()) {
        return "height: calc(100vh - 90px)";
        //return "height: calc(100vh - 90px); backgroundImage: url("+require('@/assets/gradient.png') + ")";
      } else {
        return "height: 20rem;";
      }
    },
    shouldBeTall() {
      if (!this.isMobile()) {
        return "fullHeight";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";

.container-xl {
  max-width: 1400px;
}

.container {
  position: relative;
  width: 100%;
}

.bottomPad {
  padding-bottom: 5rem;
}

.icon {
  color: $primary;
}
.gradient {
  background-repeat: no-repeat;
  background-size: 100vw calc(100vh - 90px);
}

.background {
  background-color: rgb(173, 178, 215);
}

.label-text {
  position: absolute;
  top: 6%;
  left: 3%;
  color: $primary;
  font-size: 4.4rem;
}

.label-text-mobile {
  position: absolute;
  top: 3%;
  left: 5%;
  color: $primary;
  font-size: 2rem;
}
</style>
