<template>
  <b-container
    class="gradient"
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/gradient.png') + ')',
    }"
  >
    <b-container fluid="xl"
    v-if="!this.mobile">
      <b-row align-h="center" class="text-center pt-3">
        <b-col cols="12" md="3" class="text-center">
          <b-card style="border-radius: 15px;" class="mt-2">
            <h3 style="height: 2rem;">Features</h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="opacity: 0%; height: 7.5rem;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p>App Name</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p>App Icon</p>
                  </b-td>

                  <b-td style="height: 3rem">
                    <p>One-Time Set Up Fee</p>
                  </b-td>

                  <b-td style="opacity: 0%; height: 5rem;">
                    empty
                  </b-td>

                  <b-td style="height: 3.5rem">
                    <b>Includes</b>
                  </b-td>

                  <b-td style="height: 4rem">
                    Advisory Branding
                  </b-td>

                  <b-td style="height: 4rem">
                    Client App with Unlimited Users
                  </b-td>

                  <b-td style="height: 4rem">
                    Redtail Integration
                  </b-td>

                  <b-td style="height: 4rem">
                    Compliance Disclosures
                  </b-td>

                  <b-td style="height: 4rem">
                    Advisory-Client Connectivity
                  </b-td>

                  <b-td style="height: 4rem">
                    Client Referrals
                  </b-td>

                  <b-td style="height: 4rem">
                    Advisory Pro App
                  </b-td>

                  <b-td style="height: 4rem">
                    Additional Integrations and Customization
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Minimum Number of Advisors</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Required Account Aggregation Source</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>CRM Integration</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Works on</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Advanced Security and 2-factor Authentication</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Private Hosting on Advisor Firm Database</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Management of Plain Wealth hosted app</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Management of Advisor hosted app</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p>Ongoing Customer Support</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>

        <b-col cols="3" class="text-center">
          <b-card style="border-radius: 15px;" class="mt-2 primary">
            <h3 style="height: 2rem; color: white;">
              Advisory
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="height: 7rem">
                    <p style="color: white;">
                      <span class="priceText">$70/month</span><br /> per advisor<br />*billed monthly
                    </p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;">Plain Wealth</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;">Plain Wealth</p>
                  </b-td>

                  <b-td style="height: 3rem;">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="height: 5rem;">
                     <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                 <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                 <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    empty
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    empty
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: white;">1+</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: white;">Redtail Technology CRM</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: white;">iOS (Apple)<br />Android</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: white;" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>

        <b-col cols="3" class="text-center">
          <b-card style="border-radius: 15px;" class="mt-2 info">
            <h3 style="height: 2rem; color: black;">
              Advisory Pro
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="height: 7rem">
                    <p style="color: black;">
                      <span class="priceText">$90/month</span><br /> per advisor<br />*billed monthly
                    </p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;">Your Firm Name</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;">Your Firm Brand</p>
                  </b-td>

                  <b-td style="height: 3rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 5rem;">
                     <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                 <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    empty
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">10+</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">Redtail Technology CRM</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">iOS (Apple)<br />Android</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="opacity: 0%; height: 4rem;">
                    empty
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>

        <b-col cols="3" class="text-center">
          <b-card style="border-radius: 15px; background-color: #bfbfbf" class="mt-2">
            <h3 style="height: 2rem; color: black;">
              Custom Pro
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                   <b-td style="height: 7rem">
                    <p style="color: black;">
                      <span class="priceText">Quote</span><br /> per advisor<br />*billed monthly
                    </p>
                  </b-td>

                  <b-td style="height: 2rem;">
                    <p style="color: black;">Advisory Name</p>
                  </b-td>

                  <b-td style="height: 2rem;">
                    <p style="color: black;">Advisory Brand</p>
                  </b-td>

                  <b-td style="height: 3rem;">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 5rem;">
                    <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">50+</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">Varies</p>
                  </b-td>

                   <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">iOS (Apple)<br />Android</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <p style="color: black;">TBD</p>
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>

                  <b-td style="height: 4rem">
                    <font-awesome-icon icon="check" style="color: black;" />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid v-else>
      <b-row>
        <b-col>
          <b-card style="border-radius: 15px;" class="mt-5 primary text-center">
            <h3 style="height: 2rem; color: white;">
              Advisory
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="height: 7rem">
                    <p style="color: white;">
                      <span class="priceText">$25/month*</span><br /> per advisor
                    </p>
                  </b-td>

                  <b-td style="height: 5rem;">
                    <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-0">Plain Wealth app name and icon appear in Apple App Store and in Android Google Play</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-5"><br />Once inside the app, the advisory firm's branding is seen by clients</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-5"><br /><br /><br />Minimum # of advisors:  1+</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-5"><br /><br /><br /><br />Works on iOS (Apple) and Android</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-5"><br /><br /><br /><br /><br /><u>Requires:</u></p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: white;" class="mt-5 text-left"><br /><br /><br /><br /><br />1) Redtail Technology CRM (for integration)<br />2) * One-time setup fee</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>

        <b-col>
          <b-card style="border-radius: 15px;" class="mt-5 info text-center">
            <h3 style="height: 2rem; color: black;">
              Advisory Pro
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                  <b-td style="height: 7rem">
                    <p style="color: black;">
                      <span class="priceText">$90/month*</span><br /> per advisor
                    </p>
                  </b-td>

                  <b-td style="height: 5rem;">
                    <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-0">Advisory firm's name and icon appear in Apple App Store and in Android Google Play</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br />Once inside the app, the advisory firm's branding is seen by clients</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br /><br /><br />Minimum # of advisors:  10+</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br /><br /><br /><br />Works on iOS (Apple) and Android</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br /><br /><br /><br /><br /><u>Requires:</u></p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5 text-left"><br /><br /><br /><br /><br />1) Redtail Technology CRM (for integration)<br />2) * One-time setup fee</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>

        <b-col>
          <b-card style="border-radius: 15px; background-color: #bfbfbf" class="mt-5 mb-5 text-center">
            <h3 style="height: 2rem; color: black;">
              Custom Pro
            </h3>
            <b-table-simple stacked borderless>
              <b-tbody>
                <b-tr>
                   <b-td style="height: 5rem">
                    <p style="color: black;">
                      <span class="priceText">Contact*</span>
                    </p>
                  </b-td>

                  <b-td style="height: 4rem;">
                    <b-button @click="goToCal()" variant="light" class="mt-0">
                      Schedule Demo
                    </b-button>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-0">Increased customization options including CRM integration and financial account integration</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br />Advisory firm's name and icon appear in Apple App Store and in Android Google Play</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-3"><br /><br /><br /><br /><br />Once inside the app, the advisory firm's branding is seen by clients</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br /><br /><br /><br /><br /><br />Minimum # of advisors:  50+</p>
                  </b-td>

                  <b-td style="height: 2rem">
                    <p style="color: black;" class="mt-5"><br /><br /><br /><br /><br /><br /><br />Works on iOS (Apple) and Android</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>

                  <b-td style="opacity: 0%;">
                    <p>empty</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  created() {
       if (window.innerWidth <= 576){
         this.mobile = true;
       }
     },
  data() {
    return {
      tiers: [
        {
          name: "Plain Wealth Hosted App",
          price: "$70 per month per advisor",
          color: "secondary",
        },
        {
          name: "Advisor Hosted App",
          price: "$90 per month per advisor",
          color: "primary",
        },
        {
          name: "Custom Solution",
          price: "Custom Quote",
          color: "info",
        },
      ],
      items: [{ name: "$70 per month per advisor" }],
      features: [
        {
          pricing: "Pricing",
          appStoreName: "Plain Wealth",
          appStoreIcon: "Custom",
        },
      ],
      mobile: false
    };
  },
  methods: {
    getTextColor(color) {
      if (color == "info") {
        return "secondaryText";
      } else if (color == "primary") {
        return "infoText";
      } else {
        return "primaryText";
      }
    },
     goToCal(){
      window.open("https://calendly.com/david-thorne/demo", "_blank");
      },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";

.secondary {
  background-color: $secondary;
}

.secondaryText {
  color: $secondary;
}

.info {
  background-color: $info;
}

.infoText {
  color: $info;
}

.primary {
  background-color: $primary;
}

.primaryText {
  color: $primary;
}

.tall {
  background-color: rgb(173, 178, 215);
}

.priceText {
  font-size: 2.25rem;
}
</style>
