<template>
  <div>
    <div v-if="!mobile">
    <b-container
    
      id="topImage"
      class="fullHeight"
      fluid
      :style="{
        backgroundImage: 'url(' + require('@/assets/people-phone-background.jpg') + ')',
      }"
    
    >
    <b-row class="topOfTop text-center" align-v="center">
    
      
      <b-col cols="12"><div class="catchPhrase">GIVE YOUR CLIENTS INSTANT ACCESS</div></b-col>
      <b-col cols="12"><div v-if="!medium" class="catchPhraseUnder">To Financial Information, To Service, and To Your Brand</div></b-col>

    </b-row>
    <div class="footer bottomOfTop">
    <b-row>
      <b-col cols="7">
          <div v-if="medium" class="catchPhraseUnderMedium">To Financial Information, To Service, and To Your Brand</div>
          <div class="introFooter">The Plain Wealth App</div>
          <b-row class="d-flex justify-content-center">
   
          <b-button @click="goToCalTop()" variant="primary">Schedule A Demo</b-button>
         </b-row>
        
      </b-col>
      <b-col>
        <b-img width="270" center src="@/assets/three-in-one.png" alt="Center image"></b-img>
      </b-col>
    </b-row>
    </div>
    </b-container>

    <b-container >
      <b-row class="d-flex justify-content-center topBrandToFirmPad">
        
           <b-img
            src="@/assets/text logo about.png"
           
            fluid
            style="maxWidth: 35%"
          />
          <h5 class = "text-plus">+</h5>
       
  
          <b-img
            id="redlogo"
            src="@/assets/RTlogo.png"
          
            fluid
            style="maxWidth: 30%"
          />
       
      </b-row>
      <b-row class="d-flex justify-content-center">
          <h1 class = "textModern">THE MODERN CLIENT WEALTH EXPERIENCE</h1>
      </b-row>
     
      <b-row>
        <p class="modernPara">With Plain Wealth and Redtail, you can offer your clients the seamless experience they’ve been wanting, plain and simple. Just the information they want, financial and informational, and the feel of a closer relationship with you.</p>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-list-group flush>    
            <b-list-group-item class="listText" v-for="benefit in benefits" :key="benefit.name" >
                <b-row v-if="benefit.normal" align-v="center">
                  
                  <b-col md="1">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col class="3">
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <h4 class="mb-0 text-sm">{{benefit.name}}</h4>
                      </div>
                    </div>
                    <p class="text-sm mb-0">{{benefit.details}}</p>
                  </b-col>
                </b-row>
                <b-row v-if="!benefit.normal" align-v="center">
                  
                  <b-col md="1">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col class="3">
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <h4 class="mb-0 text-sm">{{benefit.name}}</h4>
                      </div>
                    </div>
                    <p> </p>
                    <b-button @click="goToCal()"  variant="outline-primary">
                     Schedule A Demo
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
  
        </b-col>
      
        <b-col cols="1" />
        <b-col cols="4">
            <b-img
              src="../assets/home phone.png"
              contain
              fluid
              style="maxWidth: 100%"
            />
        </b-col>
        <b-col cols="1" />
      </b-row>
      <b-row class="medPad">
        <b-col cols="1" />
        <b-col cols="4">
            <b-img
              src="../assets/referrals.png"
              contain
              fluid
              style="maxWidth: 100%"
            />
        </b-col>
        <b-col cols="1" />
          <b-col cols="12" md="6">
          <b-list-group flush>    
            <b-list-group-item v-for="benefit in moreBenefits" :key="benefit.name" >
                <b-row v-if="benefit.normal" align-v="center">
                  <b-col md="1">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col class="3">
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <a @click="handleSelectedBenefit(benefit)"><h4 class="mb-0 text-sm moreBene"><u>{{benefit.name}}</u></h4></a>
                      </div>
                    </div>
                    <p class="text-sm mb-0">{{benefit.details}}</p>
                  </b-col>
                </b-row>
                <b-row v-if="!benefit.normal" align-v="center">
                  <b-col md="1">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col class="3">
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <a @click="handleSelectedBenefit(benefit)"><h4 class="mb-0 text-sm moreBene"><u>{{benefit.name}}</u></h4></a>
                      </div>
                    </div>
                    <p> </p>
                    <b-button @click="goToCal()"  variant="outline-primary">
                     Learn How Easy It Is!
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
      </b-row>


   
    </b-container>
    </div>
    <div v-if="mobile">
      <b-container
      class="gradient mb-5"
      fluid
      :style="{
        backgroundImage: 'url(' + require('@/assets/gradient.png') + ')',
      }"
    >

    <b-row class="text-center" align-v="center">
      <div class="catchPhraseMobile">GIVE YOUR CLIENTS INSTANT ACCESS</div>
      <div class="catchPhraseUnderMobile">To Financial Information, To Service, and To Your Brand</div>
    </b-row> 
    <b-row class="text-center" align-v="center">
        <b-col>
          <b-img
            src="../assets/home phone.png"
            contain
            fluid
            style="maxWidth: 50%"
          />
            <b-button @click="goToCal()" variant="primary">
              Schedule A Demo
            </b-button>
        
        </b-col>
    </b-row>
  
     
   
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-list-group flush>    
            <b-list-group-item class="listText" v-for="benefit in benefits" :key="benefit.name" >
                <b-row v-if="benefit.normal" align-v="center">
                  <b-col cols="1">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col cols="11">
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <h4 class="mb-0 text-sm">{{benefit.name}}</h4>
                      </div>
                    </div>
                    <p class="text-sm mb-0">{{benefit.details}}</p>
                  </b-col>
                </b-row>
                <b-row v-if="!benefit.normal" align-v="center">
                  <b-col cols="1">
                    <p></p>
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col cols="11" >
                   
                    <p> </p>
                    <b-button @click="goToCal()"  variant="outline-primary">
                     Schedule A Demo
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
  
        </b-col>
      
      
      </b-row>
      
      <b-row>
        <b-col cols="1" />
        <b-col >
            <b-img
              src="../assets/referrals.png"
              contain
              fluid
              style="maxWidth: 100%"
            />
        </b-col>
        <b-col cols="1" />
      </b-row>
      
      
      <b-row>
        <b-col cols="12">
          <b-list-group flush>    
            <b-list-group-item v-for="benefit in moreBenefits" :key="benefit.name" >
                <b-row v-if="benefit.normal" align-v="center">
                  <b-col cols="2">
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col >
                    <div class="d-flex justify-content-between align-items-left">
                      <div>
                        <a @click="handleSelectedBenefit(benefit)"><h4 class="mb-0 text-sm moreBene"><u>{{benefit.name}}</u></h4></a>
                      </div>
                    </div>
                    <p class="text-sm mb-0">{{benefit.details}}</p>
                  </b-col>
                </b-row>
                <b-row v-if="!benefit.normal" align-v="center">
                  <b-col cols="2" >
                    <p></p>
                    <font-awesome-icon
                        :icon="benefit.icon"
                        class="icon"
                        size="lg"
                      />
                  </b-col>
                  <b-col >
                    <p></p>
                    <b-button @click="goToCal()"  variant="outline-primary">
                     Learn How Easy It Is!
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-col>
      </b-row>
    </b-container>
    </div>
  </div>
  
</template>

<script>

//import ReusableAnimation from "../components/ReusableAnimation.vue";

require("vue2-animate/dist/vue2-animate.min.css");

export default {
  name: "HomeApp",
  components: {
  
   // ReusableAnimation,
  },
  data() {
    return {
      medium: false,
      benefits: [
        {
          normal: true,
          name: "Instant Access to Financial Information",
          icon: "university",
          details: `Delight your clients with access to their own financial dashboard, including account summary and detail.`,
        },
        {
          normal: true,
          name: "Seamless Access to Service",
          icon: "share-square",
          details: `Satisfy your client's need for service with one-touch request buttons that put them in queue for you.`,
        },
        {
          normal: true,
          name: "Your Brand, Your Personal Touch",
          icon: "address-card",
          details: `Manage your client expectations with your branded app allowing the personal touch while, at the same time, maximizing efficiency.`,
        },
        {
          normal: true,
          name: "Easy As 1,2,3",
          icon: "running",
          details: `Get started fast.  We designed the platform so that you can get setup in less than an hour, imprint your brand, and integrate your Redtail.`,
        },
         {
          normal: false,
          name: "Find Out More",
          icon: "mobile-alt",
          details: `D`,
        },
       
      ],
      moreBenefits: [
        {
          normal: true,
          name: "Features",
          icon: "draw-polygon",
          details: `Imprint your clients with all of your branding elements, your logo and your color scheme. Your clients care about your brand. 82% of U.S. investors believe brand strength matters.`,
        },
        { normal: true,
          name: "Compliance",
          icon: "balance-scale",
          details: `You get a fully functioning and compliant platform with Plain Wealth, to give your clients privacy and protection, your disclosures, and BrokerCheck.`,
        },
        { normal: true,
          name: "Security",
          icon: "shield-alt",
          details: `With Plain Wealth your clients are secure with industry standard security: multi-factor authentication, biometrics, secure storage, and end-to-end encryption.`,
        },
        {
          normal: true,
          name: "Referrals",
          icon: "user-friends",
          details: `Your delighted clients are satisfied clients.  We make the referral process effortless. Click referrals to schedule a demo where we show you how.`,
        },
         {
          normal: false,
          name: "Get Started",
          icon: "mobile-alt",
          details: `D`,
        },
       
      ],
      mobile: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
    };
  },
  created() {
    console.log(window.innerWidth);
    if (window.innerWidth < 900) {
      this.mobile = true;
    }
    else if (window.innerWidth < 1164){
      this.medium = true;
    }
   

  },
  methods: {
    goToCal(){
      window.open("https://calendly.com/david-thorne/demo", "_blank");
    },
     goToCalTop(){
       console.log("go to cal top clicked");
      window.open("https://calendly.com/david-thorne/demo", "_blank");
    },
    handleSelectedBenefit(item){
      var benefit = item.name;
      if (benefit == "Features"){
        this.$router.push({ name: "Features" });
       
      }
      if (benefit == "Security"){
        this.$router.push({ name: "Security" });
   
      }
        if (benefit == "Security"){
        this.$router.push({ name: "Security" });
        
      }
      if (benefit == "Compliance"){
        this.$router.push({ name: "Compliance" });
        
      }
      if (benefit == "Referrals"){
            window.location.assign("https://calendly.com/david-thorne/demo");
        
      }
      if (benefit == "Get Started"){
            window.location.assign("https://calendly.com/david-thorne/demo");
        
      }
      

       // you can also handle toggle action here manually to open and close dropdown
     },
    goToApple(){
       window.location.assign("https://apps.apple.com/us/app/plain-wealth/id1574037057");
      },
    goToGoogle(){
       window.location.assign("https://play.google.com/store/apps/details?id=com.plainsolutions.plainwealth.pw");
      }
  },
};
</script>

<style lang="scss">
@import "../scss/theme.scss";
.icon {
  color: $primary;
}
.topBrandToFirmPad {
  padding-top: 40px;
}

.topPad {
  padding-top: 4rem;
}

.medPad {
  padding-top: 1rem;
}

.topFormBut {
  padding-top: 4px;
}


.fullHeight {
  height: calc(100vh);
}
#topImage{
 background-attachment: fixed;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
}

.fullHeightMobile {
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
my-1 {
  color: $info;
  font-family: "Poppins", sans-serif;
}
.para-info {
  font-family: "Poppins", sans-serif;
}

.my-1 {
  font-size: large;
  font-family: "Poppins", sans-serif;
}

.quote {
  color: $info;
}

.gradient {
  background-repeat: no-repeat;
  background-size: 100vw calc(100vh - 90px);
}

.catchPhrase{
  padding-top: 3rem;
  color: white!important;
  font-family: "Poppins", sans-serif;
  //text-align: center;
  font-size: 4rem;
};

.catchPhraseMobile{
  padding-top: 1rem;
  padding-left: 16px;
  padding-right: 16px;
  color: $secondary;
  font-family: "Poppins", sans-serif;
  //text-align: center;
  font-size: 2rem;
};
.catchPhraseUnder{
  padding-top: 1rem;
  color: $primary;
  font-family: "Poppins", sans-serif;
 
  font-size: 2.5rem;
};
.catchPhraseUnderMedium{
  padding-top: 1rem;
  color: $primary;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 2.0rem;
};

.catchPhraseUnderMobile{
  padding-top: 1.0rem;
  padding-bottom:  1.0rem;
  color: $primary;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Poppins", sans-serif;
  //text-align: center;
  font-size: 1.5rem;
};

.introFooter{
  padding-top: 4rem;
  padding-bottom: 1rem;
  color: white!important;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 2.5rem;
}
.introFooterApp{
  padding-top: 10px;
  color: white!important;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 2.5rem;
}




.topOfTop{
   margin-bottom: 295px;
}

.topOfTopMobile{
   margin-bottom: 213px;
}
.bottomOfTopMobile{
  position: absolute;
  bottom: 0;
  width: 94%;
  height: 213px;
}

.bottomOfTop{
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 295px;
}



.moreBene{
  color: $primary;
 
  
}
.moreBene:hover{
  color: $info;
}
.text-plus{
  color: black;
  font-size: 3em;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
#redlogo {
  padding-bottom: 12px;
}
.textModern{
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 3rem;
  color:$primary;
  padding-top: 20px;
}
.modernPara{
  margin-left: 200 px;
  margin-right: 200 px;
  text-align: center;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.listText{
  margin: top 20px;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}
</style>
