<template>
  
  <b-navbar type="dark" variant="primary" class="navbar" toggleable="lg" fluid :style="getStyle()">
    <b-navbar-brand to="/">
      <img src="@/assets/logo.png" alt="logo" contain height="35px" />
      Plain Wealth
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/">Home</b-nav-item>
        <b-nav-item to="/features">Features</b-nav-item>
        <b-nav-item to="/security">Security</b-nav-item>
        <b-nav-item to="/compliance">Compliance</b-nav-item>
        <b-nav-item to="/pricing">Pricing</b-nav-item>
        <b-nav-item to="/about">About</b-nav-item>
        <b-nav-item to="/contactUs">Contact Us</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
   data() {
    return {
      mobile: false,
      }
    },
    created() {
        console.log("created called")
    if (window.innerWidth < 576) {
      this.mobile = true;
      console.log("scr less")
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth < 576;
    },
      

    getStyle() {
      return this.isMobile() ? "" : "height: 70px;"
    }
  },
};
</script>

<style lang="scss">
@import "../scss/theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}

.nav-item {
  font-family: "Poppins", sans-serif;
}

.navbar-dark .navbar-nav .nav-link {
  color: #e5ad45 !important;
}
.navbar-brand {
  color: #e5ad45 !important;
  font-family: "Poppins", sans-serif;
}

#nav a.router-link-exact-active {
  color: #e5ad45;
}

</style>
