<template>
  <b-card
    :title="cardTitle"
    border-variant="light"
    class="text-center"
    bg-variant="primary"
    style="border-radius: 15px;"
  >
    <b-card-text   class="text-left" style="color: white;">{{ cardBody }}</b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "HelpfulCard",
  data() {
    return {};
  },
  props: {
    cardTitle: String,
    cardBody: String,
  },
  methods: {},
};
</script>
