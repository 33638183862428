<template>
  <div>
    <b-container
      :class="getContainerHeight()"
      class="gradient"
      fluid
      style="background: radial-gradient(circle at 0% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%), radial-gradient(circle at 100% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%)"
     v-if=!mobile
    >
      <b-row align-v="center" :class="getContainerHeight()">
        
        <b-col class="text-center" cols="md-6">
           <b-img
                src="@/assets/text logo.png"
                contain
                fluid
                style="maxWidth: 80%"
              />
          <h1>Privacy & Security</h1>
         
        </b-col>
          <b-col class="text-center" cols="md-6">
           <b-img
                src="@/assets/security converted.png"
                contain
                fluid
                style="maxWidth: 80%"
              />
         
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mb-5">
      <h1 class="text-center" v-if=mobile>Security</h1>
        
        <b-img
          src="@/assets/security converted.png"
          fluid
          style="maxWidth: 100%"
         
          v-if=mobile
         />
      <b-row class="topPad" v-for="feature in features" :key="feature.name">
        <b-col cols="2" class="text-right" v-if="!isMobile()">
          <ReusableAnimation animation="fade">
            <font-awesome-icon
              :icon="feature.icon"
              class="h-75 w-25 text-right icon"
            />
          </ReusableAnimation>
        </b-col>

        <b-col cols="12" md="9">
          <ReusableAnimation animation="fade">
            <div>
              <h3 :ref="feature.name">{{ feature.name }}</h3>
              {{ feature.details }}
            </div>
          </ReusableAnimation>
        </b-col>
      </b-row>
    </b-container>

    
  </div>
</template>

<script>
import ReusableAnimation from "../components/ReusableAnimation.vue";

export default {
  components: {
    ReusableAnimation,
  },
  data() {

    return {
      mobile: false,
      features: [
        {
          name: "Two Factor Authentication",
          icon: "lock",
          details: `Two factor authentication is used to securely sign into the app. This additional defense measure greatly reduces security risks.`,
        },
        {
          name: "Biometrics",
          icon: "fingerprint",
          details: `Biometric authentication is used to secure Plain Wealth when your client is logged in. Whether a unique face signature or their fingerprint you can know their information is safe.`,
        },
        {
          name: "End to End Encryption",
          icon: "server",
          details: `Plain Wealth uses End-to-end encryption creating a secure line of network communication that blocks third-party users and malicious attackers from accessing transferred account and client data. When the data is being transferred online, only the sender and recipient can decrypt it with a unique digital key.`,
        },
        {
          name: "Secure Storage",
          icon: "key",
          details: `Data is encrypted at rest behind 11 layers of security.`,
        },
         {
          name: "24x7x365 Data Center Security",
          icon: "user-shield",
          details: `Our data is secured in centers which contain security checkpoints, cameras, and alarms and 24x7x365 security staff on-site to respond to potential threats and unauthorized activity.`,
        },
        {
          name: "Digital Threat Detection",
          icon: "laptop-code",
          details: `We utilize modern threat detection techniques to scan for malicious attacks and formulate threat response measures.`      },
      ],
    };
  },
     beforeCreate() {
       if (window.innerWidth < 576){
         this.mobile = true;
       }
     },
  methods: {
    isMobile() {
      return window.innerWidth < 576;
    },
    getContainerHeight() {
      return this.mobile ? "fullHeightMobile" : "fullHeight";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";

  .icon {
    color: $primary;
  }
</style>