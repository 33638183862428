<template>
  <div>
    <b-container
      :class="getContainerHeight()"
      class="gradient"
      fluid
      style="background: radial-gradient(circle at 0% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%), radial-gradient(circle at 100% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%)"
      v-if="!mobile"
    >
      <b-row align-v="center" :class="getContainerHeight()">
        <b-col class="text-center" cols="md-5">
          <b-img
            src="@/assets/text logo about.png"
            contain
            fluid
            style="maxWidth: 80%"
          />
          <h2>A Redtail Integration Partner</h2>
        </b-col>
        <b-col id="plus_image" align-v="center" cols="md-2">
          <b-img src="@/assets/plus-sign.png" />
        </b-col>
        <b-col class="text-center" cols="md-5">
          <b-img
            id="redlogo"
            src="@/assets/RTlogo.png"
            contain
            fluid
            style="maxWidth: 63%"
          />
          <h2>CRM for the Financial Advisor</h2>
        </b-col>
      </b-row>
      <b-row align-v="center"> </b-row>
    </b-container>
    <b-container fluid v-if="!mobile">
      <b-row class="blue-back" v-if="!mobile">
        <b-col cols="lg-5" v-if="!medium" offset="1">
          <p></p>
          <h2 class="blue-text">
            David Thorne, CEO
          </h2>
          <h3 class="white-text">
            On our Redtail partnership
          </h3>
          <h4>
            Plain Wealth offers Registered Investment Advisor (RIA) clients the
            simple, seamless experience they’ve been wanting. No ads. No pushy
            sales feel. Just the information they want, financial and
            informational, and the feel of a close advisor relationship. Joining
            Redtail for our product launch was an easy decision. This
            partnership launch is a result of right-place, right-product, and
            right-partner, a true win for wealth management clients and
            advisors. Now Redtail can offer an effortless client app solution.
          </h4>
        </b-col>
        <b-col cols="lg-5" offset="1" v-if="!medium">
          <p></p>
          <b-img
            v-if="!medium"
            src="@/assets/david_thorne_ceo.png"
            contain
            style="maxWidth: 95%"
          />
        </b-col>

        <b-col v-if="medium" class="text-center" cols="6">
          <p></p>
          <h2>
            David Throne, CEO
          </h2>
          <h3 class="white-text">
            On our Redtail partnership
          </h3>
          <b-img
            v-if="medium"
            src="@/assets/david_thorne_head.png"
            contain
            rounded="circle"
            style="maxWidth: 80%"
          />
          <p></p>
        </b-col>
        <b-col v-if="medium">
          <p></p>
          <h4>
            Plain Wealth offers Registered Investment Advisor (RIA) clients the
            simple, seamless experience they’ve been wanting. No ads. No pushy
            sales feel. Just the information they want, financial and
            informational, and the feel of a close advisor relationship. Joining
            Redtail for our product launch was an easy decision. This
            partnership launch is a result of right-place, right-product, and
            right-partner, a true win for wealth management clients and
            advisors. Now Redtail can offer an effortless client app solution.
          </h4>
        </b-col>
      </b-row>

      
      <h1 class="mt-5">About Us</h1>

      <h4>
        Plain Wealth is a part of the Plain Solutions family of companies. It is the first internal product launch since the
        global pandemic started. The company offers award-winning technology and
        innovation, through a collaborative process. Plain Wealth is committed
        to client-centric innovation, driving toward financial wellness, and is
        committed to high satisfaction customer solutions and support.
      </h4>
    </b-container>

    <b-container
      class="fullHeight gradient"
      fluid
      style="background: radial-gradient(circle at 0% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%), radial-gradient(circle at 100% 0%, rgba(0,62,105,0.5) 1%, rgba(255,255,255,0) 15%)"
      v-if="mobile"
    >
      <b-img
        src="@/assets/text logo.png"
        fluid
        style="maxWidth: 100%"
        class="pw_im_margin"
      />
      <b-img id="im_center" src="@/assets/plus-sign.png" />
      <b-img
        id="rt_logo"
        src="@/assets/RTlogo.png"
        fluid
        style="maxWidth: 100%"
      />
      <h4 id="effortless" class="blue-text">
        An Effortless Client App Experience
      </h4>
    </b-container>

   

    <b-container fluid v-if="mobile">
      <h1 class="pt-5">About Us</h1>

      <h4>
        Plain Wealth is a part of the Plain Solutions family of companies,
        founded in 2018. It is the first internal product launch since the
        global pandemic started. The company offers award-winning technology and
        innovation, through a collaborative process. Plain Wealth is committed
        to client-centric innovation, driving toward financial wellness, and is
        committed to high satisfaction customer solutions and support.
      </h4>
    </b-container>
  </div>
</template>

<script>
//import ReusableAnimation from "../components/ReusableAnimation.vue";
require("vue2-animate/dist/vue2-animate.min.css");

export default {
  name: "AboutApp",
  components: {
    //ReusableAnimation,
  },
  data() {
    return {
      mobile: false,
      medium: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
    };
  },
  created() {
    if (window.innerWidth < 576) {
      console.log("mobile should be true");
      this.mobile = true;
    }
    if (window.innerWidth < 992) {
      console.log("medium = true");
      this.medium = true;
    }
    console.log(window.innerWidth);
  },
  methods: {
    isMobile() {
      console.log("is mobile");
      return window.innerWidth < 576;
    },
    getContainerHeight() {
      return this.mobile ? "fullHeightMobile" : "fullHeight";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/theme.scss";
.topPad {
  padding-top: 4rem;
}
.fullHeight {
  height: calc(100vh - 90px);
}

.fullHeightMobile {
  height: 100vh;
}

h1,
my-1 {
  color: $info;
  font-family: "Poppins", sans-serif;
}

.white-text {
  color: white !important;
  font-family: "Poppins", sans-serif;
}

.blue-text {
  color: $primary !important;
  font-family: "Poppins", sans-serif;
}

h2 {
  font-family: "Poppins", sans-serif;
}

h4,
h5 {
  color: black;
  font-family: "Poppins", sans-serif;
}
.para-info {
  font-family: "Poppins", sans-serif;
}
#redlogo {
  padding-bottom: 8px;
}

.my-1 {
  font-size: large;
  font-family: "Poppins", sans-serif;
}

.quote {
  color: $info;
}

.gradient {
  background-repeat: no-repeat;
  background-size: 100vw calc(100vh - 90px);
}
.blue-back {
  background-color: #d4e1ec;
}
#effortless {
  margin-top: 20px;
  text-align: center;
}

#plus_image {
  text-align: center;
}

#rt_logo {
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

#im_center {
  margin-top: 15px;
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.pw_im_margin {
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
