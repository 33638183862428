<template>
<div ref="target">
  <transition :name="animation">
    <slot v-if="animate"></slot>
  </transition>
</div>
</template>

<script>
import { onMounted, ref } from "@vue/composition-api";

export default {
  name: "ReusableAnimation",
  props: {
    animation: String
  },
  setup() {
    const target = ref();
    const animate = ref(false);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (animate.value == false) {
          animate.value = entry.isIntersecting;
        }
      },
      {
        threshold: 0.1,
      }
    );
    onMounted(() => {
      observer.observe(target.value);
    });
    return {
      animate,
      target,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>