<template>
  <b-container>
    <b-card border-variant="white">
      <b-card-title>PRIVACY POLICY</b-card-title>
    </b-card>

    <b-card align="left" class="mb-2">
      <b-card-text v-html="text"></b-card-text>
    </b-card>
  </b-container>
</template>

<script>
var html = `

Dear Plain Wealth/SFC app user,

As a valued user of Plain Wealth/SFC App user, we prioritize your privacy and data security. In accordance with our commitment to comply with industry regulations and best practices, we want to inform you of your right to manage the data you have entrusted to us.

If you wish to delete your account and/or any associated data with Plain Wealth, please follow these steps:

1. **Initiate the Request**: Send an email to support@plainwealth.io.

2. **Provide Necessary Details**: In your email, please include your legal name and the email address associated with your Plain Wealth account.

3. **Specify Your Request**: Clearly state your preference, whether it is the deletion of your account, or the deletion of both your account and all related data.

Upon receipt of your request, we will engage in a correspondence to verify your identity. This is a crucial step to ensure the security of your account and the safeguarding of your personal information. Once your identity is confirmed, we will proceed with the necessary actions as per your request.

Rest assured, we handle every request with utmost confidentiality and diligence, in line with industry compliance standards.

Thank you for choosing Plain Wealth. Your trust in our services is greatly valued.

Sincerely,
Plain Solution, LLC`;

export default {
  name: "SFCDisplayPrivacy",
  components: {},
  data: function() {
    return {
      text: html
    };
  }
};
</script>

<style></style>
